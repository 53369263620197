@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-modal .ant-modal-content {
    background-color: #E1F1E4;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #fff
}

.Change_Password .ant-input-affix-wrapper {
    border-color: #000;
    padding: 10px;
}

.ant-popover .ant-popover-inner {
    padding: 0;
}

* {
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
}

.ant-btn {
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
    border-inline-end: 1px solid #000;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    border-bottom: 1px solid #000;
}

.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container {
    border-inline-start: 1px solid #f0f0f0;
    border-top: 1px solid #000;
}

.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
    border-bottom: 1px solid #000;
}

.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>.ant-table-cell-fix-right-first::after {
    border-inline-end: 1px solid #000;
}

:where(.css-dev-only-do-not-override-1op8cm1).ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
    margin-top: 12px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;
}

.ant-modal .ant-modal-content {
    background-color: #E1F1E4;
}

/* border-inline-start: 1px solid #000;  */
.ant-table-selection-column {
    border-inline-start: 1px solid #000;
}

.ant-table-cell:first-child {
    border-inline-start: 1px solid #000;
}

.ant-checkbox .ant-checkbox-inner {
    border: 1px solid #000;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-picker {
    border: 1px solid #000;
}

.ant-table-cell {
    color: #000;
    font-weight: 500;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
}

.ant-select .ant-select-arrow {
    inset-inline-end: 0px;
}

.ps .ant-input {
    background: transparent;
    border: none;
}

.ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-1op8cm1).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-1op8cm1).ant-table-wrapper tfoot>tr>th,
.ant-table-wrapper tfoot>tr>td {
    padding: 5px;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper tfoot>tr>th,
.ant-table-wrapper tfoot>tr>td {
    padding: 5px;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper tfoot>tr>th,
.ant-table-wrapper tfoot>tr>td {
    padding: 5px;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.ant-table-wrapper .ant-table-container {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 0
}


.border-input {
    border-bottom: 1px solid black;
}

.border-input:last-child {
    border-bottom: 0px;
}

.ax .ant-input {
    border-color: #ccc;
}

.ax .ant-form-item {
    margin-bottom: 0 !important;
}

.ant-table-wrapper .ant-table {
    background-color: rgba(217, 217, 217, 0.3)
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    background-color: rgba(217, 217, 217, 0.3)
}

.ant-table-wrapper .ant-table-cell-fix-left,
.ant-table-wrapper .ant-table-cell-fix-right {
    background-color: rgba(217, 217, 217, 0.3)
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>td,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
    background-color: rgba(217, 217, 217, 0.3)
}

.ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-hk751d).ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: rgba(217, 217, 217);

}

.st .ant-empty-normal {
    min-height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-input {
    background: transparent;
}

.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container {
    border-inline-start: none
}

.ant-pagination-item-Infinity {
    display: none !important;
}

.x .ant-table-selection-column {
    width: 65px !important;
}